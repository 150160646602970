/* 전체 레이아웃 */
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(to bottom, #1a1a1a, #000000);
  color: white;
  font-family: 'Arial', sans-serif;
  text-align: center;
  padding: 0 20px;
}

/* Header 스타일 */
.header {
  padding: 50px 0;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  color: #ffd700; /* 골드 컬러 */
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.5rem;
  color: #ccc;
}

/* Main 섹션 스타일 */
.main {
  margin: 20px 0;
}

.open-text {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
}

.date-text {
  font-size: 1.5rem;
  color: #bbb;
  margin: 10px 0;
}

.notice-text {
  font-size: 1rem;
  color: #777;
}

/* Footer 스타일 */
.footer {
  padding: 20px 0;
  background: #111;
}

.footer-text {
  font-size: 0.9rem;
  color: #aaa;
}

.footer-text a {
  color: #ffd700;
  text-decoration: none;
}

.footer-text a:hover {
  text-decoration: underline;
}

.social-links {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-link {
  font-size: 0.9rem;
  color: #aaa;
  text-decoration: none;
  transition: color 0.3s;
}

.social-link:hover {
  color: white;
}
